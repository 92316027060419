/* TODO: Separate CSS for each page into its own file? */

@import url(https://fonts.googleapis.com/css?family=Roboto:300);

body {
  background-color: #2e2930;
  font-family: 'Roboto', sans-serif;

  overflow: hidden;
}

.App {
  text-align: center;
}

@keyframes colorcycle {
  from {
    color: #aaaaaa;
    text-shadow: 0 0 1px white;
  }
  to {
    color: #f8f8f8;
    text-shadow: 0 0 4px white;
  }
}

.Gallery-loading {
  font-size: 24px;
  color: #bbb;
  text-shadow: 0 0 1px white;
  margin-top: 20vh;
  animation-name: colorcycle;
  animation-duration: 2s;
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.Gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  box-sizing: border-box;
  width: 100%;
  height: 100vh;
}

.display {
  display: block;
  overflow: hidden;
  background-color: #2e2930;
  color: white;
  font-size: calc(10px + 2vmin);
  margin: 10px;
}

.display-media {
  object-fit: contain;
  width: 100%;
  height: 100%;

  border-radius: 6px;
}

.display-link {
  width: 100%;
  height: 100%;
}

.header {
  line-height: 1;
  width: 100%;
  padding: 0.35em 0em;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background-color: #423b46;
  border-bottom: #b19fbad8 1px outset;
  box-shadow: inset -1px -3px 12px 1px #7c6e8275;

  /* border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; */
}

.header-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  font-size: min(calc(10px + 2vw), 20px);
  color: white;

  width: 25vw;
  margin-left: 1vw;
}

.header-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  font-size: min(calc(10px + 1vw), 20px);
  color: white;
}

.header-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  font-size: min(calc(8px + 2vw), 16px);
  font-weight: 200;
  color: #bbb9bd;
  width: 25vw;
  margin-right: 1vw;
  padding-top: 0.25em;
  transition: all 0.2s;
}

.header-details:hover {
  color: white;
}

.header-details-date {
  user-select: all;
  cursor: default;
  margin: 0vw 0.35vw;
}

.header-details-time {
  user-select: all;
  cursor: default;
  margin: 0vw 0.35vw;
}

.header-details-credit {
  user-select: none;
  margin: 0vw 0.35vw;
  font-size: 0.8em;
  width: 6em;
  text-align: right;
}

.header-menu-btn {
  text-decoration: none;
  user-select: none;
  cursor: pointer;
  display: inline-block;
  
  font-size: min(calc(10px + 1vw),20px);
  padding: calc(0.15em + 0.2vw) calc(0.5em + 0.2vw);
  text-align: center;
  margin-left: 0.3vw;
  margin-right: 0.3vw;

  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #ffffff;
  border: 0.1em solid #ffffff;
  border-radius: 0.5em;
  box-sizing: border-box;
  transition: all 0.2s;
}

.header-menu-btn:hover {
  color: #423b46;
  background-color: #ffffff;
}

.header-menu-btn.active:not(:hover) {
  background-color: #8e6a8a;
  border-color: #e0d6df;
  text-shadow: 0 0 16px #ffffffa0;
}

/* 'header-menu-utils' contains 'Download' and 'New tab' header buttons */
.header-menu-utils {
  /* margin: 0 2vw 0 2vw; */
  margin-left: 2.5vw;
  padding: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.header-util {
  margin: 0 0.4vw 0 0.4vw;
}
.header-util-btn {
  user-select: none;
  cursor: pointer;
  display: inline-block;
  font-size: 0.5vw;
  padding: calc(0.05em + 0.15vw) calc(0.35em + 0.15vw);
  border: 0.1em solid #ffffff;
  border-radius: 0.5em;
  box-sizing: border-box;
  transition: all 0.2s;
  background-color: #fff0;
}
.header-util-btn:hover {
  background-color: #ffffff50;
}
.header-util-icon {
  width: min(25px, calc(8px + 1vw));
}


.header-nav-btn {
  text-decoration: none;
  user-select: none;
  cursor: pointer;
  display: inline-block;

  width: 2.3em;
  text-align: center;
  padding: 0.25em 0.1em;

  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  border: 0.05em solid #bbbbbb;
  border-radius: 0em;
  box-sizing: border-box;
  transition: all 0.2s;
}

.header-nav-btn:hover {
  color: #423b46;
  border-color: #ffffff;
  background-color: #ffffff;
}

.header-nav-btn.active:not(:hover) {
  background-color: #8e6a8a;
  text-shadow: 0 0 16px #ffffffa0;
}

.header-nav-btn.first-last:not(:hover) {
  color: #b7a9bd;
  border-color: #bcb2c2;
}

.header-nav-btn.prev-next:not(:hover) {
  color: #d7d4d8;
  border-color: #dad8db;
}

/* .header-nav-btn.number { } */

#navbtn_first {
  border-radius: 0.75em 0em 0em 0.75em;
}
#navbtn_last {
  border-radius: 0em 0.75em 0.75em 0em;
}


/* Gallery -> Display */
#g_photo_display {
  display: block;
}

#g_video_display {
  display: block;
}

/* Gallery -> Details */
#g_photo_details {
  display: block;
}

#g_video_details {
  display: block;
}

/* Media by type */

/* #md_photo { } */

/* #md_video { } */


/* Index */
.Index {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 18pt;
  font-weight: 600;
  color: #eeeeee;

  margin: auto;
  height: 100vh;
  padding: 4vh 5vw;
  background-size: cover;
  background-position-y: 50%;
}

/* Index -> Links */
.Index a {
  color: #eeeeee;
  text-decoration: none;
}
.Index a:visited {
  color: #dddddd;
}
.Index a:hover {
  color: #f4f4f4;
}
.Index a:active {
  color: #ffffff;
}

/* Index -> List */
.Index-list-item {
  margin: 1rem 0 1rem 0;
  font-size: 20pt;
}
.Index-list-item-link {
  vertical-align: middle;
}


.ErrorPage {
  color: #dddddd;
  margin: 5rem;
}

.error-page-msg-title {
  font-size: 1.1rem;
  font-weight: 600;
}
.error-page-msg-text {
  font-style: italic;
  color: #d6aaaa;
  border-style: solid;
  border-radius: 12px;
  padding: 1rem;
  border-width: 2px;
  border-color: #d6aaaa;
  background-color: hsl(288 7% 12% / 1);
  box-shadow: inset 0px 0px 10px 2px hsl(291deg 6% 16% / 90%);
}

.Gateway {
  margin: auto;
  height: 100vh;
  padding: 4vh 5vw;
  background-size: cover;
  background-position-y: 50%;
}

.Gateway-prompt {
  font-size: min(1.2rem, calc(1rem + 0.2vw));
  color: #f2f2f2;
  font-weight: 700;
  text-align: center;
  margin-top: 4rem;
}

.Gateway-password-form {
  position: relative;
  z-index: 1;
  background: #ffffff;
  max-width: 380px;
  margin: 3rem auto 10rem;
  padding: 46px 48px 22px 48px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  border-radius: 12px;
}
.Gateway-password-form button {
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #8e6d8b;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 0.7rem;
}
.Gateway-password-form button:hover,.Gateway-password-form button:active,.Gateway-password-form button:focus {
  background: #82627f;
}
.Gateway-password-textbox-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}
.Gateway-password-textbox-box {
  font-family: 'Roboto', sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.Gateway-password-textbox-x {
  position: absolute;
  right: 8px;
  top: 21%;
  pointer-events: none;
  opacity: 0.75;
}
.Gateway-password-remember-box {
  width: 1.5rem;
  vertical-align: bottom;
}
.Gateway-password-remember-text {
  font-size: 14px;
}
.Gateway-password-error-message {
  color: #850c05;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 4px;
  min-height: 24px;
  opacity: 1;
}
.error-message-hidden {
  opacity: 0;
  transition: opacity 1.5s linear;
  pointer-events: none;
}